<template>
  <v-content>
    <v-container class="fill-height azul" fluid>
      <v-row align="center" justify="center" class="mx-0">
        <v-col cols="12" sm="8" md="4">
          <v-card class="elevation-12 py-1">
            <v-img
              height="77"
              src="@/assets/images/logo.png"
              class="ma-5 pa-5"
              contain
            ></v-img>
            <v-toolbar color="#1B6BAA" dark flat>
              <v-toolbar-title style="width: 100%"
                ><div style="text-align: center">Sales</div></v-toolbar-title
              >
            </v-toolbar>
            <v-card-text>
              <v-form>
                <v-text-field
                  label="User"
                  name="user"
                  prepend-icon="mdi-account"
                  type="text"
                  v-model="datosLogin.user"
                />

                <v-text-field
                  id="password"
                  label="Password"
                  name="password"
                  prepend-icon="mdi-lock"
                  type="password"
                  v-model="datosLogin.password"
                />
              </v-form>
            </v-card-text>
            <v-card-actions>
              <v-spacer />
              <v-btn color="primary" @click="login">Login</v-btn>
            </v-card-actions>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </v-content>
</template>
<style scoped>
.azul {
  background: #1b6baa;
}
</style>

<script>
export default {
  data: () => ({
    datosLogin: {
      user: "",
      password: "",
    },
  }),
  methods: {
    login: function () {
      var este = this;
      var variables = {
        email: este.datosLogin.user,
        password: este.datosLogin.password,
      };
      //if(window.OneSignal){
      if (process.env.NODE_ENV == "production") {
        //window.OneSignal.getUserId().then(function(userId) {
        //console.log("OneSignal User ID:", userId);
        //variables.onesignal = userId;
        variables.onesignal = "12345";
        este.$http
          .post(este.$api + "login", variables)
          .then(function (response) {
            window.console.log(response);
            if (response.status == 200 && response.data.status == "ok") {
              console.log("IMPRIME DATOS");
              console.log(response.data.data);
              este.$ls.set("token", response.data.data[0].token);
              este.$ls.set("driver_id", response.data.data[0].id);
              este.$ls.set("signature", response.data.data[0].signature);
              este.$ls.set("driver_type", response.data.data[0].type);
              este.$ls.set("driver_onesignal", response.data.data[0].onesignal);
              var datos = {
                token: response.data.data[0].token,
                driver_id: response.data.data[0].id,
                signature: response.data.data[0].signature,
                type: response.data.data[0].type,
                driver_onesignal: response.data.data[0].onesignal,
              };
              este.$store.commit("login", datos);
              este.$router.push("/");
            } else {
              var mensaje = {
                color: "red",
                timeout: 5000,
                message: response.data.msg,
              };
              este.$store.commit("msgMuestra", mensaje);
            }
          })
          .catch(function (err) {
            var mensaje = {
              color: "red",
              timeout: 5000,
              message: "Wrong user / password. Please try again.",
            };
            este.$store.commit("msgMuestra", mensaje);
            window.console.log(err);
          });
        //});
      } else {
        console.log("Development");
        variables.onesignal = 12345;
        este.$http
          .post(este.$api + "login", variables)
          .then(function (response) {
            window.console.log(response);
            if (response.status == 200 && response.data.status == "ok") {
              console.log("IMPRIME DATOS");
              console.log(response.data.data);
              este.$ls.set("token", response.data.data[0].token);
              este.$ls.set("driver_id", response.data.data[0].id);
              este.$ls.set("signature", response.data.data[0].signature);
              este.$ls.set("driver_type", response.data.data[0].type);
              este.$ls.set("driver_onesignal", response.data.data[0].onesignal);
              var datos = {
                token: response.data.data[0].token,
                driver_id: response.data.data[0].id,
                signature: response.data.data[0].signature,
                type: response.data.data[0].type,
                driver_onesignal: response.data.data[0].onesignal,
              };
              este.$store.commit("login", datos);
              este.$router.push("/");
            } else {
              var mensaje = {
                color: "red",
                timeout: 5000,
                message: response.data.msg,
              };
              este.$store.commit("msgMuestra", mensaje);
            }
          })
          .catch(function (err) {
            var mensaje = {
              color: "red",
              timeout: 5000,
              message: "Wrong user / password. Please try again.",
            };
            este.$store.commit("msgMuestra", mensaje);
            window.console.log(err);
          });
      }
      /*} else {
            var mensaje = {
                "color": "red",
                "timeout": 5000,
                "message": "Push Notifications Missing."
            }
            este.$store.commit("msgMuestra", mensaje);
            window.console.log(err);
          }*/
    },
  },
  mounted: function () {
    if (this.$store.getters.token) {
      this.$router.push("/");
    }
  },
};
</script>
