<template>
  <div class="routes py-3">
    <div>
      <v-card class="mx-auto">
        <v-toolbar color="#E21A22" dark>
          <v-btn icon @click="prev">
            <v-icon>mdi-chevron-left</v-icon>
          </v-btn>
          <v-spacer></v-spacer>
          <v-toolbar-title>{{ dateFormat }}</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn icon @click="next">
            <v-icon>mdi-chevron-right</v-icon>
          </v-btn>
        </v-toolbar>
        <v-list three-line v-if="!routes.length">
          <template>
            <v-list-item-content>
              <v-list-item-title>
                <div class="px-5">{{ message }}</div>
              </v-list-item-title>
            </v-list-item-content>
          </template>
        </v-list>
        <GmapMap
          ref="map"
          v-if="routes.length"
          :center="{ lat: 33.9190068, lng: -118.1200275 }"
          :position="google && new google.maps.LatLng(33.9190068, -118.1200275)"
          :zoom="14"
          :key="id"
          style="width: 100%; height: 400px"
          class="mx-auto"
        >
        </GmapMap>
        <v-list three-line v-if="routes.length" class="py-0">
          <template v-for="(item, index) in routes[0].customers">
            <v-divider :key="'d-' + index"></v-divider>
            <v-list-item
              :key="'c-' + index"
              @click="muestraRuta(index)"
              :class="'class' in item ? item.class : ''"
            >
              <v-list-item-avatar :key="'letter-' + index" color="#EA4335">
                <span class="white--text headline text-center mx-auto">{{
                  letters[index]
                }}</span>
              </v-list-item-avatar>
              <v-list-item-content>
                <v-list-item-title
                  v-html="'<strong>' + item.businessName + '</strong>'"
                ></v-list-item-title>
                <v-list-item-subtitle
                  v-html="item.serviceAddress"
                ></v-list-item-subtitle>
                <v-list-item-subtitle
                  v-html="item.contactPerson + ' - ' + item.phoneNumber"
                ></v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </template>
        </v-list>
      </v-card>
    </div>
    <v-row justify="center">
      <v-dialog
        v-model="dialog"
        fullscreen
        hide-overlay
        transition="dialog-bottom-transition"
      >
        <v-card>
          <v-toolbar dark color="#1B6BAA">
            <v-btn icon dark @click="dialog = false">
              <v-icon>mdi-chevron-left</v-icon>
            </v-btn>
            <v-toolbar-title>{{ route.businessName }}</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-toolbar-items> </v-toolbar-items>
          </v-toolbar>
          <GmapMap
            v-if="route"
            :center="center"
            :zoom="14"
            style="width: 100%; height: 400px"
            class="mx-auto"
          >
            <GmapMarker
              :key="index"
              v-for="(m, index) in markers"
              :position="m.position"
              :clickable="true"
              @click="center = m.position"
            />
          </GmapMap>
          <v-stepper v-model="e1">
            <v-stepper-header>
              <v-stepper-step
                :complete="e1 > 1"
                step="1"
                @click="
                  realStep = e1;
                  e1 = 1;
                "
                >Start Route</v-stepper-step
              >

              <v-divider></v-divider>

              <v-stepper-step :complete="e1 > 2" step="2"
                >Check In</v-stepper-step
              >

              <v-divider></v-divider>

              <v-stepper-step :complete="e1 > 3" step="3"
                >Signatures & Notes</v-stepper-step
              >

              <v-divider></v-divider>

              <v-stepper-step step="4">Complete Route</v-stepper-step>
            </v-stepper-header>

            <v-stepper-items>
              <v-stepper-content step="1" class="">
                <v-card class="mb-6 elevation-5 mx-auto" outlined>
                  <v-toolbar color="#E21A22" dark>
                    <v-toolbar-title>Details</v-toolbar-title>
                  </v-toolbar>
                  <v-card-text>
                    <div class="red darken-1 px-2" v-if="route.notes_to_driver">
                      <p class="white--text">
                        <strong>Notes from Office:</strong>
                        {{ route.notes_to_driver }}
                      </p>
                    </div>
                    <p>
                      <strong>Business name:</strong> {{ route.businessName }}
                    </p>
                    <p>
                      <strong>Address:</strong>
                      {{ route.serviceAddress }}
                    </p>
                    <p>
                      <strong>Days and hours of operation:</strong><br />
                      <span
                        v-for="(dh, ind) in route.daysAndHoursOfOperation"
                        :key="'dh-' + ind"
                        >- {{ dh.name }}<br
                      /></span>
                    </p>
                    <p><strong>E-mail:</strong> {{ route.email }}</p>
                    <p>
                      <strong>Phone number:</strong> {{ route.phoneNumber }}
                    </p>
                    <p>
                      <strong>Contact person at clinic:</strong>
                      {{ route.contactPerson }}
                    </p>
                    <p><strong>Notes:</strong> {{ route.notes }}</p>
                  </v-card-text>
                </v-card>
                <div class="text-center">
                  <v-btn
                    color="primary"
                    @click="startRoute"
                    class="mx-auto"
                    :loading="loading"
                    :disabled="loading"
                    v-if="realStep == 1"
                  >
                    Start Route
                  </v-btn>
                  <v-btn
                    color="primary"
                    @click="e1 = realStep"
                    class="mx-auto"
                    v-else
                  >
                    Resume
                  </v-btn>
                </div>
              </v-stepper-content>

              <v-stepper-content step="2">
                <v-card class="mb-6 elevation-5 mx-auto" outlined>
                  <v-toolbar color="#E21A22" dark>
                    <v-toolbar-title>Activities</v-toolbar-title>
                  </v-toolbar>
                  <v-card-text>
                    <v-list-item-title class="headline mb-1"
                      >Step 2</v-list-item-title
                    >
                    <p class="text-center">
                      Check In at customer service address. If you need driving
                      directions, please press Directions.
                    </p>
                    <p class="text-center">
                      If upon arrival the business is closed, please press
                      Closed to take a picture and complete the route.
                    </p>
                    <div class="text-center">
                      <v-btn
                        color="blue mt-5 mb-5 mr-5"
                        class="white--text"
                        @click="directions"
                      >
                        Directions
                      </v-btn>
                      <v-btn
                        color="green mt-5 mb-5"
                        class="white--text"
                        :loading="loading2"
                        :disabled="loading2"
                        @click="checkIn"
                      >
                        Check In
                      </v-btn>
                      <v-btn
                        color="red mt-5 mb-5"
                        class="white--text"
                        :loading="loading5"
                        :disabled="loading5"
                        @click="closed"
                      >
                        Closed
                      </v-btn>
                      <input
                        ref="camera"
                        v-show="false"
                        type="file"
                        accept="image/*"
                        @change="addPicture"
                        capture="camera"
                        id="camera"
                      />
                    </div>
                  </v-card-text>
                </v-card>
              </v-stepper-content>

              <v-stepper-content step="3">
                <v-card class="mb-6 elevation-5 mx-auto" outlined>
                  <v-toolbar color="#E21A22" dark>
                    <v-toolbar-title>Signatures & Notes</v-toolbar-title>
                  </v-toolbar>
                  <v-card-text>
                    <v-list-item-title class="headline"
                      >Notes to Office</v-list-item-title
                    >
                    <v-textarea
                      v-model="notes"
                      class="px-4"
                      auto-grow
                    ></v-textarea>
                    <v-list-item-title class="headline mb-1"
                      >Seller's signature</v-list-item-title
                    >
                    <div class="caption">
                      <strong>Please sign</strong>
                    </div>
                    <VueSignaturePad
                      width="320px"
                      height="300px"
                      id="driverSignature"
                      ref="driverSignature"
                      class="mx-auto elevation-5 my-5"
                    />
                    <v-btn
                      v-if="$store.getters.signature"
                      color="success"
                      @click="driverSign"
                      class="mb-5 mr-3"
                    >
                      Sign
                    </v-btn>
                    <v-btn color="gray" @click="undoDriver" class="mb-5">
                      Undo signature
                    </v-btn>
                  </v-card-text>
                </v-card>
                <div class="text-center">
                  <v-btn
                    color="red mt-5 mb-5"
                    class="white--text"
                    :loading="loading5"
                    :disabled="loading5"
                    @click="closed"
                  >
                    Closed
                  </v-btn>
                  &nbsp;
                  <v-btn
                    color="primary"
                    @click="send"
                    class="mx-auto"
                    :loading="loading4"
                    :disabled="loading4"
                  >
                    Continue
                  </v-btn>
                </div>
              </v-stepper-content>

              <v-stepper-content step="4">
                <v-card class="mb-6 elevation-5 mx-auto" outlined>
                  <v-toolbar color="#E21A22" dark>
                    <v-toolbar-title>Completed Route</v-toolbar-title>
                  </v-toolbar>
                  <v-card-text>
                    <div class="text-center">
                      <p v-if="route.status == 'Completed'">
                        You have completed this route.
                      </p>
                      <p v-if="route.manifestNumber" class="font-weight-black">
                        Manifest Number: {{ route.manifestNumber }}
                      </p>
                      <div
                        class="text-center"
                        v-if="
                          route.status == 'Completed' && route.allowEdit == 1
                        "
                      >
                        <v-btn
                          color="primary"
                          @click="editManifest"
                          class="mx-auto"
                          :loading="loading6"
                          :disabled="loading6"
                        >
                          Edit Route
                        </v-btn>
                      </div>
                      <p v-if="route.status == 'Closed'">
                        The business was closed as shown in the following
                        picture.
                      </p>
                      <v-img
                        v-if="route.status == 'Closed'"
                        :src="imageUrl + route.image"
                      ></v-img>
                      <v-btn
                        color="green mt-5 mb-5"
                        class="white--text"
                        @click="reset"
                        v-if="route.status == 'Closed'"
                        :loading="loading5"
                        :disabled="loading5"
                      >
                        Reset
                      </v-btn>
                      <v-btn
                        color="primary"
                        @click="nextRoute"
                        class="mx-auto"
                        v-if="
                          routes.lenght &&
                          parseFloat(routeIndex) + 1 <
                            routes[0].customers.length
                        "
                      >
                        Next Route
                      </v-btn>
                    </div>
                  </v-card-text>
                </v-card>
              </v-stepper-content>
            </v-stepper-items>
          </v-stepper>
        </v-card>
      </v-dialog>
    </v-row>
  </div>
</template>
<style scoped>
.onroute {
  background: #e3f2fd;
}

.checkin {
  background: #bbdefb;
}

.extrasnotes {
  background: #90caf9;
}

.completed {
  background: #42a5f5;
}

.closed {
  background: #f44336;
}
</style>
<script>
import { gmapApi } from "vue2-google-maps";
export default {
  data: () => ({
    qtys: [],
    e1: 1,
    id: 0,
    realStep: 1,
    routes: [],
    mapsLoaded: false,
    route: "",
    routeIndex: "",
    center: "",
    points: [],
    markers: [],
    message: "",
    dialog: false,
    letters: [
      "B",
      "C",
      "D",
      "E",
      "F",
      "G",
      "H",
      "I",
      "J",
      "K",
      "L",
      "M",
      "N",
      "O",
      "P",
      "Q",
      "R",
      "S",
      "T",
      "U",
      "V",
      "X",
      "Y",
      "Z",
    ],
    dateFormat: "",
    date: "",
    notes: "",
    location: [],
    extras: [],
    driverSignature: "",
    customerSignature: "",
    customerName: "",
    picture: "",
    loading: false,
    loading2: false,
    loading3: false,
    loading4: false,
    loading5: false,
    loading6: false,
    editManifestOp: false,
  }),
  computed: {
    google: gmapApi,
    imageUrl: function () {
      if (process.env.NODE_ENV == "development") {
        return "http://prima.test/storage/";
      } else {
        return "https://primawm.com/storage/";
      }
    },
  },
  watch: {
    date: function () {
      this.dateFormat = this.$moment(this.date).format("dddd, MMMM Do, YYYY");
    },
    e1: function (val) {
      var este = this;
      if (val == 4) {
        setTimeout(function () {
          este.$refs.driverSignature.resizeCanvas();
          este.$refs.customerSignature.resizeCanvas();
          este.$refs.customerSignature.clearSignature();
        }, 500);
        setTimeout(function () {
          este.getSignatures();
        }, 1000);
      }
    },
  },
  methods: {
    directions: function () {
      window.open(
        "https://www.google.com/maps/search/?api=1&query=" +
          this.route.location[0].lat +
          "," +
          this.route.location[0].lng
      );
    },
    getSignatures: function () {
      var este = this;
      var laurl = "getSignatures";
      var datos = "";
      datos = {
        driver_id: este.$store.getters.driver_id,
        token: este.$store.getters.token,
        routeschedule_id: este.route.routeschedule_id,
      };
      este.$http.post(este.$api + laurl, datos).then(function (response) {
        if (response.status == 200 && response.data.status == "ok") {
          if (response.data.driverSignature != "") {
            este.$refs.driverSignature.fromDataURL(
              response.data.driverSignature
            );
          }
          if (response.data.customerSignature != "") {
            este.$refs.customerSignature.fromDataURL(
              response.data.customerSignature
            );
          }
          if (response.data.contactPersonAtClinic != "") {
            este.customerName = response.data.contactPersonAtClinic;
          }
        }
      });
    },
    driverSign() {
      this.$refs.driverSignature.fromDataURL(this.$store.getters.signature);
      console.log(this.$store.getters.signature);
    },
    undoDriver() {
      this.$refs.driverSignature.undoSignature();
    },
    undoCustomer() {
      this.$refs.customerSignature.undoSignature();
    },
    prev: function () {
      this.$route.params.route_back = -1;
      this.date = this.$moment(this.date)
        .subtract(1, "day")
        .format("YYYY-MM-DD");
      this.show();
    },
    next: function () {
      this.$route.params.route_back = -1;
      this.date = this.$moment(this.date).add(1, "day").format("YYYY-MM-DD");
      this.show();
    },
    send() {
      var este = this;
      este.loading4 = true;
      const { isEmpty, data } = this.$refs.driverSignature.saveSignature();
      //var estaVacio = true;
      //var losdatos = "";
      //estaVacio, (losdatos = this.$refs.customerSignature.saveSignature());
      //if (isEmpty || losdatos.isEmpty || este.customerName == "") {
      if (isEmpty) {
        console.log(isEmpty);
        //console.log(losdatos.isEmpty);
        //console.log(este.customerName);
        var mensaje = {
          color: "red",
          timeout: 5000,
          message: "Please sign before submitting.",
        };
        este.loading4 = false;
        este.$store.commit("msgMuestra", mensaje);
      } else {
        este.driverSignature = data;
        //este.customerSignature = losdatos.data;
        // Obtiene location
        este
          .$getLocation()
          .then((coordinates) => {
            console.log(coordinates);
            este.location = coordinates;
            este.$http
              .post(este.$api + "complete", {
                driver_id: este.$store.getters.driver_id,
                token: este.$store.getters.token,
                driverSignature: este.driverSignature,
                //customerSignature: este.customerSignature,
                //customerName: este.customerName,
                location: este.location,
                routeschedule_id: este.route.routeschedule_id,
                notes: este.notes,
              })
              .then(function (response) {
                console.log(response);
                if (response.status == 200 && response.data.status == "ok") {
                  console.log("Route completed.");
                  este.editManifestOp = false;
                  este.e1 = 5;
                  este.driverSignature = "";
                  //este.customerSignature = "";
                  //este.customerName = "";
                  este.notes = "";
                  este.getExtras();
                  este.loading4 = false;
                  este.routes[0].customers[este.routeIndex].status =
                    "Completed";
                  este.routes[0].customers[este.routeIndex].class = "completed";
                  //este.routes[0].customers[este.routeIndex].manifestNumber = response.data.manifestNumber;
                } else {
                  var mensaje = {
                    color: "red",
                    timeout: 5000,
                    message: "There was an error. Please try again.",
                  };
                  este.loading4 = false;
                  este.$store.commit("msgMuestra", mensaje);
                }
              })
              .catch(function (err) {
                var mensaje = {
                  color: "red",
                  timeout: 5000,
                  message: "There was an error. Please try again.",
                };
                este.$store.commit("msgMuestra", mensaje);
                este.loading4 = false;
                window.console.log(err);
              });
          })
          .catch(function (err) {
            var mensaje = {
              color: "red",
              timeout: 5000,
              message: "We couldn't get your location'. Please try again.",
            };
            este.loading4 = false;
            este.$store.commit("msgMuestra", mensaje);
            window.console.log(err);
          });
      }
    },
    closed: function () {
      this.$refs.camera.click();
    },
    reset: function () {
      this.e1 = 1;
      this.editManifestOp = false;
      this.$route.params.route_back = -1;
      var este = this;
      este.loading5 = true;
      este
        .$getLocation()
        .then((coordinates) => {
          console.log(coordinates);
          este.location = coordinates;
          var laurl = "reset";
          var datos = "";
          datos = {
            driver_id: este.$store.getters.driver_id,
            token: este.$store.getters.token,
            routeschedule_id: este.route.routeschedule_id,
            location: este.location,
            route: este.route.route,
          };
          este.$http
            .post(este.$api + laurl, datos)
            .then(function (response) {
              if (response.status == 200 && response.data.status == "ok") {
                console.log("Reset");
                este.loading5 = false;
                este.routes[0].customers[este.routeIndex].status = "Pending";
                este.routes[0].customers[este.routeIndex].class = "";
                este.e1 = 1;
                window.console.log(response.status);
              } else {
                var mensaje = {
                  color: "red",
                  timeout: 5000,
                  message: response.data.msg,
                };
                este.$store.commit("msgMuestra", mensaje);
                este.loading5 = false;
                if (
                  "error" in response.data &&
                  response.data.error == "logout"
                ) {
                  este.$router.push("/logout");
                }
              }
            })
            .catch(function (err) {
              var mensaje = {
                color: "red",
                timeout: 5000,
                message: "There was an error. Please try again.",
              };
              este.loading5 = false;
              este.$store.commit("msgMuestra", mensaje);
              window.console.log(err);
            });
        })
        .catch(function (err) {
          var mensaje = {
            color: "red",
            timeout: 5000,
            message: "We couldn't get your location. Please try again.",
          };
          este.loading5 = false;
          este.$store.commit("msgMuestra", mensaje);
          window.console.log(err);
        });
    },
    addPicture: function () {
      var este = this;
      este.loading5 = true;
      var file = document.querySelector("input[type=file]").files[0];
      var reader = new FileReader();
      reader.onload = function (e) {
        window.console.log(e.target.result);
        var image = e.target.result;
        este
          .$getLocation()
          .then((coordinates) => {
            console.log(coordinates);
            este.location = coordinates;
            var laurl = "closed";
            var datos = "";
            datos = {
              driver_id: este.$store.getters.driver_id,
              token: este.$store.getters.token,
              routeschedule_id: este.route.routeschedule_id,
              location: este.location,
              image: image,
              route: este.route.route,
            };
            este.$http
              .post(este.$api + laurl, datos)
              .then(function (response) {
                if (response.status == 200 && response.data.status == "ok") {
                  console.log("Closed");
                  este.loading5 = false;
                  este.routes[0].customers[este.routeIndex].status = "Closed";
                  este.routes[0].customers[este.routeIndex].class = "closed";
                  este.routes[0].customers[este.routeIndex].image =
                    "routeschedules/images/" +
                    este.route.routeschedule_id +
                    ".jpg";
                  este.e1 = 5;
                  window.console.log(response.status);
                } else {
                  var mensaje = {
                    color: "red",
                    timeout: 5000,
                    message: response.data.msg,
                  };
                  este.$store.commit("msgMuestra", mensaje);
                  este.loading5 = false;
                  if (
                    "error" in response.data &&
                    response.data.error == "logout"
                  ) {
                    este.$router.push("/logout");
                  }
                }
              })
              .catch(function (err) {
                var mensaje = {
                  color: "red",
                  timeout: 5000,
                  message: "There was an error. Please try again.",
                };
                este.loading5 = false;
                este.$store.commit("msgMuestra", mensaje);
                window.console.log(err);
              });
          })
          .catch(function (err) {
            var mensaje = {
              color: "red",
              timeout: 5000,
              message: "We couldn't get your location. Please try again.",
            };
            este.loading5 = false;
            este.$store.commit("msgMuestra", mensaje);
            window.console.log(err);
          });
      };
      reader.onerror = function (error) {
        var mensaje = {
          color: "red",
          timeout: 5000,
          message: "There was an error. Please try again.",
        };
        este.loading5 = false;
        este.$store.commit("msgMuestra", mensaje);
        window.console.log(error);
      };
      reader.readAsDataURL(file);
    },
    editManifest: function () {
      var este = this;
      este.loading6 = true;
      este
        .$getLocation()
        .then((coordinates) => {
          console.log(coordinates);
          este.location = coordinates;
          var laurl = "editManifest";
          var datos = "";
          datos = {
            driver_id: este.$store.getters.driver_id,
            token: este.$store.getters.token,
            routeschedule_id: este.route.routeschedule_id,
            location: este.location,
          };
          este.$http
            .post(este.$api + laurl, datos)
            .then(function (response) {
              if (response.status == 200 && response.data.status == "ok") {
                este.editManifestOp = true;
                este.loading6 = false;
                console.log("Edit Manifest Ready");
                este.routes[0].customers[este.routeIndex].status = "Check In";
                este.routes[0].customers[este.routeIndex].class = "checkin";
                este.e1 = 3;
                window.console.log(response.status);
              } else {
                var mensaje = {
                  color: "red",
                  timeout: 5000,
                  message: response.data.msg,
                };
                este.$store.commit("msgMuestra", mensaje);
                este.loading6 = false;
                if (
                  "error" in response.data &&
                  response.data.error == "logout"
                ) {
                  este.$router.push("/logout");
                }
              }
            })
            .catch(function (err) {
              var mensaje = {
                color: "red",
                timeout: 5000,
                message: "There was an error. Please try again.",
              };
              este.$store.commit("msgMuestra", mensaje);
              este.loading6 = false;
              window.console.log(err);
            });
        })
        .catch(function (err) {
          var mensaje = {
            color: "red",
            timeout: 5000,
            message: "We couldn't get your location. Please try again.",
          };
          este.loading6 = false;
          este.$store.commit("msgMuestra", mensaje);
          window.console.log(err);
        });
    },
    checkIn: function () {
      var este = this;
      este.loading2 = true;
      este
        .$getLocation()
        .then((coordinates) => {
          console.log(coordinates);
          este.location = coordinates;
          var laurl = "checkIn";
          var datos = "";
          datos = {
            driver_id: este.$store.getters.driver_id,
            token: este.$store.getters.token,
            routeschedule_id: este.route.routeschedule_id,
            location: este.location,
          };
          este.$http
            .post(este.$api + laurl, datos)
            .then(function (response) {
              if (response.status == 200 && response.data.status == "ok") {
                este.loading2 = false;
                console.log("Check In Ready");
                este.routes[0].customers[este.routeIndex].status = "Check In";
                este.routes[0].customers[este.routeIndex].class = "checkin";
                este.e1 = 3;
                window.console.log(response.status);
              } else {
                var mensaje = {
                  color: "red",
                  timeout: 5000,
                  message: response.data.msg,
                };
                este.$store.commit("msgMuestra", mensaje);
                este.loading2 = false;
                if (
                  "error" in response.data &&
                  response.data.error == "logout"
                ) {
                  este.$router.push("/logout");
                }
              }
            })
            .catch(function (err) {
              var mensaje = {
                color: "red",
                timeout: 5000,
                message: "There was an error. Please try again.",
              };
              este.$store.commit("msgMuestra", mensaje);
              este.loading2 = false;
              window.console.log(err);
            });
        })
        .catch(function (err) {
          var mensaje = {
            color: "red",
            timeout: 5000,
            message: "We couldn't get your location. Please try again.",
          };
          este.loading2 = false;
          este.$store.commit("msgMuestra", mensaje);
          window.console.log(err);
        });
    },
    startRoute: function () {
      var este = this;
      este.loading = true;
      este
        .$getLocation()
        .then((coordinates) => {
          console.log(coordinates);
          este.location = coordinates;
          var laurl = "startRoute";
          var datos = "";
          if (
            "routeschedule_id" in este.route &&
            este.route.routeschedule_id > 0
          ) {
            datos = {
              driver_id: este.$store.getters.driver_id,
              token: este.$store.getters.token,
              routeschedule_id: este.route.routeschedule_id,
              location: este.location,
            };
          } else {
            datos = {
              driver_id: este.$store.getters.driver_id,
              token: este.$store.getters.token,
              customer_id: este.route.id,
              pickUpDate: este.date,
              notes: este.route.notes,
              contactPersonAtClinic: este.route.contactPerson,
              location: este.location,
            };
          }
          este.$http
            .post(este.$api + laurl, datos)
            .then(function (response) {
              if (response.status == 200 && response.data.status == "ok") {
                este.loading = false;
                console.log("Route has been started");
                este.routes[0].customers[este.routeIndex].routeschedule_id =
                  response.data.routeschedule_id;
                este.routes[0].customers[este.routeIndex].status = "On Route";
                este.routes[0].customers[este.routeIndex].class = "onroute";
                este.e1 = 2;
                window.console.log(response.status);
              } else {
                var mensaje = {
                  color: "red",
                  timeout: 5000,
                  message: response.data.msg,
                };
                este.$store.commit("msgMuestra", mensaje);
                este.loading = false;
                if (
                  "error" in response.data &&
                  response.data.error == "logout"
                ) {
                  este.$router.push("/logout");
                }
              }
            })
            .catch(function (err) {
              var mensaje = {
                color: "red",
                timeout: 5000,
                message: "There was an error. Please try again.",
              };
              este.loading = false;
              este.$store.commit("msgMuestra", mensaje);
              window.console.log(err);
            });
        })
        .catch(function (err) {
          var mensaje = {
            color: "red",
            timeout: 5000,
            message: "We couldn't get your location. Please try again.",
          };
          este.$store.commit("msgMuestra", mensaje);
          este.loading = false;
          window.console.log(err);
        });
    },
    muestraRuta: function (index, back = 0) {
      if (back > 0) {
        index = this.routes[0].customers.findIndex((f) => f.id === index);
      }
      if (
        process.env.NODE_ENV == "development" ||
        this.date <= this.$moment().format("YYYY-MM-DD")
      ) {
        console.log(this.date);
        console.log(index);
        this.route = this.routes[0].customers[index];
        this.routeIndex = index;
        console.log(this.route);
        this.markers = [
          {
            position: {
              lat: parseFloat(this.route.location[0].lat),
              lng: parseFloat(this.route.location[0].lng),
            },
            title: this.route.businessName,
          },
        ];
        var este = this;
        este.points[index] = {
          location:
            parseFloat(este.route.location[0].lat) +
            "," +
            parseFloat(este.route.location[0].lng),
          stopover: true,
        };
        this.center = {
          lat: parseFloat(este.route.location[0].lat),
          lng: parseFloat(este.route.location[0].lng),
        };
        console.log(this.center);
        this.realStep = 1;
        if ("status" in this.route) {
          switch (this.route.status) {
            case "On Route":
              this.e1 = 2;
              break;
            case "Check In":
              this.e1 = 3;
              break;
            case "Extras / Notes":
              if (this.$route.params.route_back >= 0) {
                this.e1 = 3;
              } else {
                this.e1 = 4;
              }
              break;
            case "Completed":
            case "Closed":
              this.e1 = 4;
              break;
            default:
              this.e1 = 1;
              break;
          }
        } else {
          this.e1 = 1;
        }
        this.dialog = true;
      }
    },
    show: function () {
      var este = this;
      este.$http
        .post(este.$api + "routes", {
          driver_id: este.$store.getters.driver_id,
          token: este.$store.getters.token,
          date: este.date,
        })
        .then(function (response) {
          if (response.status == 200 && response.data.status == "ok") {
            console.log("IMPRIME DATOS");
            window.console.log(response.status);
            if (!response.data.results) {
              este.message = response.data.msg;
              este.routes = [];
            } else {
              este.routes = response.data.data;
              este.id = response.data.id;
              este.optimizeRoute(
                response.data.data.filter((item) => item.customers.length > 0)
              );
              if (este.$store.getters.gotoRoute > -1) {
                //este.muestraRuta(este.$store.getters.gotoRoute);
                este.$store.commit("selectRoute", -1);
                este.$store.commit("customer_id", 0);
                este.$store.commit("route_id", 0);
              }
            }
          } else {
            var mensaje = {
              color: "red",
              timeout: 5000,
              message: response.data.msg,
            };
            este.routes = [];
            este.$store.commit("msgMuestra", mensaje);
            if ("error" in response.data && response.data.error == "logout") {
              este.$router.push("/logout");
            }
          }
        })
        .catch(function (err) {
          este.routes = [];
          var mensaje = {
            color: "red",
            timeout: 5000,
            message: "There was an error. Please try again.",
          };
          este.$store.commit("msgMuestra", mensaje);
          window.console.log(err);
        });
    },
    optimizeRoute: function (route) {
      var este = this;
      este.routes = route;
      setTimeout(function () {
        route.forEach(function (item, index) {
          console.log(item);
          este.initMap(item, index);
        });
      }, 100);
      setTimeout(function () {
        if (este.$route.params.route_back >= 0) {
          este.muestraRuta(este.$route.params.route_back, 1);
        }
      }, 1000);
    },
    initMap: function (route, routeIndex) {
      var este = this;
      este.$refs.map.$mapPromise.then((map) => {
        var google = this.google;
        //map = este.$refs.map.$mapObject;
        var directionsService = new google.maps.DirectionsService();
        var directionsDisplay = new google.maps.DirectionsRenderer();
        directionsDisplay.setMap(map);
        var points = [];
        route.customers.forEach(function (item, index) {
          points[index] = {
            location: item.location[0].lat + "," + item.location[0].lng,
            stopover: true,
          };
        });
        console.log(points);
        var optimizeWaypoints = true;
        if (route.manualorder > 0) {
          optimizeWaypoints = false;
        }

        directionsService.route(
          {
            origin: "33.9190068,-118.1200275",
            destination: "33.9190068,-118.1200275",
            travelMode: "DRIVING",
            waypoints: points,
            optimizeWaypoints: optimizeWaypoints,
          },
          function (response, status) {
            if (status === "OK") {
              // Acomoda la lista de la ruta
              if (optimizeWaypoints) {
                response.routes[0].waypoint_order.forEach(function (it, ind) {
                  este.routes[routeIndex].customers[it].order = ind;
                });
                este.routes[routeIndex].customers.sort((a, b) =>
                  a.order > b.order ? 1 : -1
                );
              } else {
                /*este.routes[routeIndex].customers.forEach(function(ii, kk){
                                este.routes[routeIndex].customers[kk].order = kk;
                            });*/
                este.routes[routeIndex].customers.sort((a, b) =>
                  a.order > b.order ? 1 : -1
                );
              }
              // Pass data to the map
              directionsDisplay.setDirections(response);

              // See the data in the console
              console.log(response);
            } else {
              var mensaje = {
                color: "red",
                timeout: 5000,
                message: "Directions request failed due to " + status,
              };
              este.$store.commit("msgMuestra", mensaje);
            }
          }
        );
      });
    },
  },
  mounted: function () {
    var este = this;
    console.log(este.$route.params.route_back);
    window.addEventListener(
      "orientationchange",
      function () {
        if (este.e1 == 4) {
          console.log("Driver Sign");
          este.undoDriver();
          este.driverSign();
        }
      },
      false
    );
    este.date = this.$moment().format("YYYY-MM-DD");
    //este.date = this.$moment(este.date).subtract(2, 'day').format("YYYY-MM-DD");
    this.$store.commit("title", "Routes");
    var i;
    for (i = 1; i <= 100; i++) {
      this.qtys.push(i);
    }
    console.log("RUTA: ");
    console.log(este.$route.params.route_back);
    if (!this.$store.getters.token || !this.$store.getters.driver_id) {
      this.$router.push("/login");
    } else {
      this.show();
    }
  },
};
</script>
